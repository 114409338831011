import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './PetDetail.css'; // Importa el archivo CSS para los estilos
import { FaPaw, FaDog, FaCat, FaBirthdayCake, FaUser } from 'react-icons/fa';
import WhatsAppButton from './WhatsAppButton'; // Importa el componente WhatsAppButton

const PetDetail = () => {
  const { id } = useParams(); // Obtener el ID de la URL
  const [pet, setPet] = useState(null);

  useEffect(() => {
    const fetchPet = async () => {
      try {
        const petDocRef = doc(db, 'pets', id);
        const petDoc = await getDoc(petDocRef);
        if (petDoc.exists()) {
          setPet(petDoc.data());
        }
      } catch (error) {
        console.error("Error fetching pet data: ", error);
      }
    };

    fetchPet();
  }, [id]);

  if (!pet) {
    return <p>Loading...</p>;
  }

  return (
    <div className="pet-detail-container">
      <div className="pet-image">
        <img src={pet.picture}alt={pet.nombre} /> {/* Reemplaza con la URL de la imagen */}
      </div>
      <div className="pet-info">
        <h1><FaPaw /> {pet.nombre}</h1>
        <p><FaDog /> <strong>Especie:</strong> {pet.especie}</p>
        <p><FaCat /> <strong>Raza:</strong> {pet.raza}</p>
        <p><strong>Sexo:</strong> {pet.sexo}</p>
        <p><FaBirthdayCake /> <strong>Fecha de Nacimiento:</strong> {new Date(pet.dob.seconds * 1000).toLocaleDateString()}</p>
        <p><FaUser /> <strong>Dueño:</strong> {pet.userName}</p>
      </div>
      <WhatsAppButton pet={pet} /> {/* Usa el componente WhatsAppButton y pasa los datos de la mascota */}

    </div>
  );
};

export default PetDetail;
