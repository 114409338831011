// src/components/WhatsAppButton.js
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppButton.css'; // Importa el archivo CSS para los estilos

const WhatsAppButton = ({ pet }) => {
  const createWhatsAppLink = () => {
    const message = `🐾 *Detalles de la Mascota* 🐾
    
🐕 *Nombre:* ${pet.nombre}
🐾 *Especie:* ${pet.especie}
🐶 *Raza:* ${pet.raza}
⚤ *Sexo:* ${pet.sexo}
🎂 *Fecha de Nacimiento:* ${new Date(pet.dob.seconds * 1000).toLocaleDateString()}
👤 *Dueño:* ${pet.userName}`;

    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/5491136102127?text=${encodedMessage}`;
  };

  return (
    <a href={createWhatsAppLink()} className="whatsapp-button">
      <FaWhatsapp /> Enviar por WhatsApp
    </a>
  );
};

export default WhatsAppButton;


