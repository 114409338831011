import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Importa el archivo CSS para los estilos
import { FaPaw } from 'react-icons/fa';

const Home = () => {
  const petId = 'QR_CHAPITA'; // Reemplazar con un ID real

  return (
    <div className="home-container">
      <h1><FaPaw /> Bienvenido a la Página de Inicio</h1>
      <Link to={`/pet/${petId}`} className="view-details-button">Ver Detalles de la Mascota</Link>
    </div>
  );
};

export default Home;
